"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import ProductsMegaMenu from "./ProductsMegaMenu";
import { useState } from "react";

const Navigation = () => {
  const pathname = usePathname();
  // const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);
  return (
    <>
      <ul className="flex sm:flex-col sm:items-start items-center space-x-14 sm:space-x-0 sm:space-y-6 text-sm font-light text-gray-400">
        <li>
          <Link
            href="/"
            className={`hover:text-white ${
              pathname === "/" ? "text-white" : ""
            }`}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            href="/our-story"
            className={`hover:text-white ${
              pathname === "/our-story" ? "text-white" : ""
            }`}
          >
            Our Story
          </Link>
        </li>
        <li
          className="group flex items-center"
          // onMouseDown={() => setIsMegaMenuVisible(true)}
          // onMouseUp={() => setIsMegaMenuVisible(false)}
          // onMouseEnter={() => setIsMegaMenuVisible(true)}
        >
          <Link
            href="/products"
            className={`hover:text-white ${
              pathname === "/products" ? "text-white" : ""
            }`}
          >
            Products
          </Link>
          <span className="group text-white inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              webcrx=""
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.71967 9.96967C8.01256 9.67678 8.48744 9.67678 8.78033 9.96967L12 13.1893L15.2197 9.96967C15.5126 9.67678 15.9874 9.67678 16.2803 9.96967C16.5732 10.2626 16.5732 10.7374 16.2803 11.0303L12.5303 14.7803C12.2374 15.0732 11.7626 15.0732 11.4697 14.7803L7.71967 11.0303C7.42678 10.7374 7.42678 10.2626 7.71967 9.96967Z"
                fill="#ffffff"
              />
              <script xmlns="" id="bw-fido2-page-script" />
            </svg>
            <ProductsMegaMenu />
          </span>
        </li>
      </ul>
      <div className="hidden sm:block mt-5">
        <Link
          className="flex items-center justify-center font-medium uppercase tracking-[0.08em] transition-colors focus:outline-none rounded-full bg-amber-500 text-neutral-900 hover:bg-orange-300 focus-visible:outline-primary-500 h-8 px-6 text-[10px] shadow-[0px_0px_20px_rgba(255,164,0,0.24)]"
          href="/contact"
        >
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Navigation;
