"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";

// ... (other imports)

export default function ProductsMegaMenu() {
  const storageURL = process.env.NEXT_PUBLIC_STORAGE_URL;
  const router = useRouter();
  const [uniqueParentCategories, setUniqueParentCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [hoveredParentCategory, setHoveredParentCategory] = useState(null);
  const [hoveredParentCategoryname, setHoveredParentCategoryname] =
    useState(null);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const [loadingViscosity, setLoadingViscosity] = useState(false);
  const [subCatAfterClick, setSubCatAfterClick] = useState();
  const [viscosity, setViscosity] = useState();
  const [numW, setNumW] = useState(0);
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSubcategories(true);
        const response = await fetch("/api/get-product-categories", {
          cache: "no-store",
        });
        const data = await response.json();
        setCategoriesAll(data);
        const nonEmptyParentcats = data.filter(
          (uniqueParentcat) => uniqueParentcat.product_cat_parent == ""
        );
        setUniqueParentCategories(nonEmptyParentcats);
        // Set the first parent category as the default hovered category
        if (nonEmptyParentcats.length > 0) {
          setHoveredParentCategory(nonEmptyParentcats[0]);
          setHoveredParentCategoryname(nonEmptyParentcats[0]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoadingSubcategories(false);
      }
    };

    fetchData();
  }, [router]);

  useEffect(() => {
    // Fetch subcategories based on the hovered parent category
    const fetchSubcategories = async () => {
      if (hoveredParentCategory) {
        try {
          setLoadingSubcategories(true);
          const response = await fetch(
            `/api/product-subcategories/${hoveredParentCategory._id}`,
            { cache: "no-store" }
          );
          const subcategoriesData = await response.json();
          setSubcategories(subcategoriesData);
          setSubCatAfterClick(subcategoriesData[0]._id);
        } catch (error) {
          console.error("Error fetching categories:", error);
        } finally {
          setLoadingSubcategories(false);
        }
      }
    };

    fetchSubcategories();
  }, [hoveredParentCategory, router]);
  const handleParentCategoryHover = (parentCategory) => {
    setHoveredParentCategory(parentCategory);
    setHoveredParentCategoryname(parentCategory);
  };

  useEffect(() => {
    // Fetch subcategories based on the hovered parent category
    const fetchSubcategories = async () => {
      if (subCatAfterClick) {
        try {
          setLoadingViscosity(true);
          const response = await fetch(`/api/get-products`, {
            cache: "no-store",
          });
          const productsData = await response.json();

          // Use a Set to track unique viscosities
          const uniqueViscosities = new Set();

          // Filter and remove duplicates based on viscosity
          const getProductsData = productsData.filter((product) => {
            if (product.product_subcategory === subCatAfterClick) {
              // Check if viscosity is already encountered
              if (!uniqueViscosities.has(product.product_viscosity)) {
                uniqueViscosities.add(product.product_viscosity);
                return true;
              }
            }
            return false;
          });

          setViscosity(getProductsData);
        } catch (error) {
          console.error("Error fetching categories:", error);
        } finally {
          setLoadingViscosity(false);
        }
      }
    };

    fetchSubcategories();
  }, [subCatAfterClick, router]);

  const handleSubCatClick = (subcatId) => {
    setSubCatAfterClick(subcatId);
  };

  const handleParentCategoryLeave = () => {
    // No need to set hoveredParentCategory to null on leave
    // to keep the active background on the active item
  };
useEffect(() => {
  setWindowWidth(window.innerWidth);
},[]);
  return (
    <>
      {uniqueParentCategories && uniqueParentCategories.length > 0 && (
        <div className={`sm:h-[50vh] sm:mb-9 absolute pt-12 left-0 invisible sm:pt-0 sm:relative sm:visible sm:opacity-100 w-full h-screen opacity-0 group-hover:opacity-100 group-hover:visible transition-all duration-300 sm:duration-1200 md:duration-1200 top-8 ${
          windowWidth < 767 ? "":"group-hover:h-[80vh]"}`}>
          <div className="container bg-slate-900 rounded-sm mx-auto border border-slate-600 relative hidden group-hover:block h-[80vh] overflow-auto sm:block sm:overflow-x-hidden sm:h-[50vh]">
            <div style={windowWidth < 767 && numW > 0 ? { transform: `translateX(-${numW}%)` } : {}}
                   className={` flex flex-row flex-wrap sm:flex-nowrap w-[300%] lg:w-full md:w-full sm:relative ${
                    windowWidth < 767 && numW > 0 ? "transition-all duration-300 delay-300 transform translate-x-[-" + numW + "%]" : "transition-all duration-300 delay-300"
                  }`}
            >
              <div className="w-2/6 bg-neutral-950 sm:w-full">
                {/* parent categoris start */}
                <ul className="font-campton-semibold text-xs text-white">
                  {uniqueParentCategories &&
                    uniqueParentCategories.map((parentCategory) => (
                      <li
                        key={parentCategory._id}
                        className={`${
                          hoveredParentCategory === parentCategory
                            ? "bg-amber-500 text-black"
                            : ""
                        }`}
                      >
                        <Link
                          className="flex gap-5 items-center py-7 px-7 text-white border-b border-slate-600"
                          href={`#${parentCategory._id}`}
                          onMouseEnter={() => {
                            handleParentCategoryHover(parentCategory);
                            setNumW(33.34);
                          }}
                          onMouseLeave={handleParentCategoryLeave}
                        >
                          {parentCategory.product_cat_image && (
                            <Image
                              src={`${storageURL}/content/icons/${parentCategory.product_cat_image}`}
                              className="w-8"
                              width={32}
                              height={32}
                              alt="catn icon"
                            />
                          )}
                          {parentCategory.product_cat_name}
                        </Link>
                      </li>
                    ))}
                </ul>
                {/* parent categoris end */}
              </div>
              <div className="w-3/6 bg-zinc-800 px-5 py-5 sm:px-0 sm:w-full relative">
                <button onClick={() => {setNumW(0)}} className="mb-4 py-1 px-4 hidden sm:block bg-amber-500 ml-3"> {`<`} Back </button>
                <div className="text-amber-500 text-sm font-semibold font-campton-semibold leading-7 mb-4 sm:px-4">
                  {hoveredParentCategoryname &&
                    hoveredParentCategoryname.product_cat_name}
                </div>
                {/* sub categoris start */}
                <ul className="text-white text-xs font-medium font-campton-medium capitalize leading-[36.4px] flex flex-col gap-3 sm:px-2">
                  {/* Show subcategories based on the hovered parent category */}
                  {subcategories &&
                    subcategories.map((subcategory) => (
                      <li key={subcategory._id}>
                        <button
                          className="flex items-center gap-3 hover:font-campton-bold hover:scale-110 transition-all duration-150"
                          onClick={() => {
                            setNumW(66.68);
                            handleSubCatClick(subcategory._id);
                          }}
                        >
                          {subcategory.product_cat_image && (
                            <Image
                              src={`${storageURL}/content/icons/${subcategory.product_cat_image}`}
                              className="w-6"
                              width={30}
                              height={30}
                              alt="cat icon"
                            />
                          )}
                          {subcategory.product_cat_name}
                        </button>
                      </li>
                    ))}
                </ul>
                {/* sub categoris end */}
              </div>
              <div className="w-1/6 bg-neutral-600 text-center sm:w-full relative">
              <button onClick={() => {setNumW(33.34)}} className="absolute mb-4 py-1 px-4 hidden sm:block bg-amber-500 ml-3 mt-4"> {`<`} Back </button>
                <ul className="text-white text-sm font-bold font-campton-medium capitalize leading-[18px]">
                  <li>
                    <p className="sm:text-right block py-5 px-5 border-b border-slate-400">
                      VISCOSITY
                    </p>
                  </li>
                  {viscosity &&
                    viscosity.map((visco) => {
                      const category = categoriesAll.find(
                        (c) => c._id === visco.product_category
                      );
                      const categorySlug = category.product_cat_slug;

                      const subcategory = categoriesAll.find(
                        (c) => c._id === visco.product_subcategory
                      );
                      const subCategorySlug = subcategory.product_cat_slug;
                      return (
                        <li key={visco._id}>
                          <Link
                            className=" block py-5 px-5 border-b border-slate-400 hover:bg-neutral-700"
                            href={`/products/${categorySlug}/${subCategorySlug}/${visco.product_viscosity
                              .toLowerCase()
                              .replace(/\s+/g, "-")
                              .replace(/-+/g, "-")
                              .replace(/[^\w-]/g, "")}`}
                          >
                            {visco.product_viscosity}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
